import { Injectable } from '@angular/core';
import { Guid, RouterActions } from '@autobot/core';
import {
  CaseType,
  CreateCaseDTO,
  Helpers,
  SelectedDisposition,
  TelemedicineConfigs,
} from '@nfr/common';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import * as moment from 'moment-timezone';
import { forkJoin, of, throwError } from 'rxjs';
import {
  catchError,
  debounceTime,
  delay,
  exhaustMap,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  CaseActions,
  CaseApiActions,
  DesktopActions,
  ProtocolActions,
} from '../actions';
import { CaseApiService } from '../services/case-api.service';

import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@autobot/shared';
import {
  AlertService,
  Assembly,
  ConfirmDialogService,
  MedicalProtocol,
  RuleService,
} from '@forms/common';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { CaseApiActionTypes } from '../actions/case-api.actions';
import {
  getAbcdProtocolForms_Sorted,
  getCaseId,
  getCasePutObject,
  getNotePutObject,
  selectAssembly,
  selectCaseMetaData,
  selectCaseState,
  selectCurrentState,
  selectModel,
  selectTelemedicineConfig,
} from '../selectors';
import { selectSingleAccount } from '@accounting/data';
import { AccountApiActions, AccountFacade } from '@accounting/data';
import { getLookUpDatasets } from '../selectors/lookup.selectors';
import { FormsApiService } from '../services/forms-api.service';

@Injectable()
export class CaseEffects {
  CASE_ACTIONS_SUCCESS = [
    CaseApiActionTypes.CREATE_CASE_SUCCESS,
    CaseApiActionTypes.LOAD_CASE_SUCCESS,
    CaseApiActionTypes.SAVE_CASE_SUCCESS,
    CaseApiActionTypes.SEARCH_CASES_SUCCESS,
  ];

  CASE_ACTIONS_FAILURE = [
    CaseApiActionTypes.CREATE_CASE_FAILURE,
    CaseApiActionTypes.LOAD_CASE_FAILURE,
    CaseApiActionTypes.SAVE_CASE_FAILURE,
    CaseApiActionTypes.SEARCH_CASES_FAILURE,
  ];

  saveCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.saveCase, CaseActions.completeCaseSuccess),
      concatLatestFrom(() => this.store.select(getCasePutObject)),
      switchMap(([_, caseData]) => {
        const { id, data } = caseData;
        const { firstName, lastName, employeeId } =
          data.intake.employee.demographics;
        const claimant = caseData.claimant;

        claimant.firstName = firstName || claimant.firstName;
        claimant.lastName = lastName || claimant.lastName;
        claimant.employeeKey = employeeId || claimant.employeeKey;

        return this.service.saveCase(id, caseData).pipe(
          switchMap(() => {
            return of(CaseApiActions.saveCaseSuccess());
          }),
          catchError((error: unknown) => {
            // Show an alert here
            if (caseData.status == 'Completed') {
              this.completeCaseFailureNotify();
            }
            // Return the error action
            return of(CaseApiActions.saveCaseFailure({ error }));
          })
        );
      })
    );
  });

  saveCaseAndNavigate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.saveCaseAndNavigate),

      concatLatestFrom(() => this.store.select(getCasePutObject)),
      switchMap(([navigate, caseData]) => {
        const { id, data } = caseData;
        const navigateTo = navigate.to;
        const { firstName, lastName, employeeId } =
          data.intake.employee.demographics;
        const claimant = caseData.claimant;

        if (firstName) claimant.firstName = firstName;
        if (lastName) claimant.lastName = lastName;
        if (employeeId) claimant.employeeKey = employeeId;

        return this.service.saveCase(id, caseData).pipe(
          exhaustMap((_) => [RouterActions.go({ to: navigateTo })]),
          catchError((error: unknown) => {
            this.note.error('Error in Save Case');
            return of(CaseApiActions.saveCaseFailure({ error }));
          })
        );
      })
    );
  });
  saveCaseAndExit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.saveCaseAndExit),
      concatLatestFrom(() => this.store.select(getCasePutObject)),
      switchMap(([, caseData]) => {
        const { id, data } = caseData;
        const { firstName, lastName, employeeId } =
          data.intake.employee.demographics;
        const claimant = caseData.claimant;

        if (firstName) claimant.firstName = firstName;
        if (lastName) claimant.lastName = lastName;
        if (employeeId) claimant.employeeKey = employeeId;

        return this.service.saveCase(id, caseData).pipe(
          exhaustMap((_) => [
            CaseApiActions.saveCaseSuccess(),
            DesktopActions.exitDesktop(),
          ]),
          catchError((error: unknown) => {
            if (caseData.status == 'Completed') {
              this.completeCaseFailureNotify();
            }
            return of(CaseApiActions.saveCaseFailure({ error }));
          })
        );
      })
    );
  });

  createCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.createCase),
      switchMap(({ call, account, agent, user }) => {
        try {
          const employee = call.props?.employee;
          const newModelData = this.bindEmployeeDetail(call, user);

          const dto: CreateCaseDTO = {
            id: Guid.newGuid(),
            claimant: {
              firstName: employee.firstName,
              lastName: employee.lastName,
              employeeKey: employee.employeeId,
            },
            accountId: account.id,
            accountName: account.name,
            caseRef: account.caseRefPrefix,
            caseType: call.caseType,
            createdBy: agent,
            updatedBy: agent,
            created: new Date(),
            updated: new Date(),
            data: { ...newModelData },
            props: {},
          };
          return this.service.createCase(dto, account.tenant).pipe(
            map((data) => CaseApiActions.createCaseSuccess({ case: data })),
            catchError((error: unknown) =>
              of(CaseApiActions.createCaseFailure({ error }))
            )
          );
        } catch (error) {
          console.error('Error in bindEmployeeDetail:', error);
          return of(CaseApiActions.createCaseFailure({ error }));
        }
      })
    );
  });

  saveNoteApi$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseApiActions.saveNoteAPI),
      concatLatestFrom(() => [
        this.store.select(getCaseId),
        this.store.select(getNotePutObject),
      ]),
      switchMap(([_, caseId, notesData]) => {
        return this.service.saveNote(caseId, notesData).pipe(
          map(() => CaseApiActions.saveNoteAPISuccess()),
          catchError((error: unknown) =>
            of(CaseApiActions.saveNoteAPIFailure({ error }))
          )
        );
      })
    );
  });

  completeCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.completeCase),
      switchMap(() => of(CaseActions.completeCaseSuccess())),
      catchError((error: unknown) =>
        of(CaseActions.completeCaseFailure({ error }))
      )
    );
  });

  searchCases$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.searchCases),
      switchMap(({ searchText, tenant }) =>
        this.service.searchCases(searchText, tenant).pipe(
          map(({ items }) =>
            CaseApiActions.searchCasesSuccess({ results: items })
          ),
          catchError((error: unknown) =>
            of(CaseApiActions.searchCasesFailure({ error }))
          )
        )
      )
    );
  });

  loadCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.loadCase),
      switchMap(({ id }) => this.service.loadCase(id)),
      concatLatestFrom(() => this.store.select(getLookUpDatasets)),
      concatLatestFrom((data) =>
        this.store.select(selectSingleAccount(data[0].accountId))
      ),
      switchMap((data) => {
        const assembly$ = this.forms.loadAssembly(data[1].assemblyId);

        return forkJoin({
          lookupResult: of(data[0][1]),
          caseResult: of(data[0][0]),
          assemblyResult: assembly$,
        });
      }),
      mergeMap(({ lookupResult, caseResult, assemblyResult }) => {
        const data = caseResult;
        const newCaseResultData = this.applyCaseBasedRule(data);
        data.data = { ...newCaseResultData };

        const assembly = {
          ...assemblyResult,
          props: { ...assemblyResult.props, datasets: lookupResult },
        };
        this.account.selectAccount(data.accountId);
        const accountId = data.accountId;
        const protocolIds = data?.protocols?.map((f) => f.id) || [];
        return [
          CaseApiActions.loadCaseSuccess({
            model: data.data,
            obj: data,
            assembly,
            notes: data.notes,
            protocols: data.protocols,
          }),
          ProtocolActions.loadProtocol({
            templateIds: protocolIds,
            protocolType: '',
          }),
          AccountApiActions.loadAccountConfigs({ id: accountId }),
        ];
      }),
      catchError((error: unknown) =>
        of(CaseApiActions.loadCaseFailure({ error }))
      )
    );
  });

  successNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...this.CASE_ACTIONS_SUCCESS),
        tap((e) => {
          const message = this.customMessage(e.type) || 'Success';
          this.note.success(message);
        })
      );
    },
    { dispatch: false }
  );

  failedNotification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(...this.CASE_ACTIONS_FAILURE),
        tap(() => this.note.error('Error'))
      );
    },
    { dispatch: false }
  );

  handleEmergencyClick$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CaseActions.handle911EmergencyClick),
        concatLatestFrom(() => this.store.select(selectCaseMetaData)),
        exhaustMap(([_, caseMetaData]) => {
          const caseType = caseMetaData.caseType;
          const confirmText =
            caseType == CaseType.Emergency ? 'de-activate' : 'activate';

          return this.confirmDialog
            .openConfirmDialog(
              'Attention',
              `<p>Are you sure want to ${confirmText} emergency case?</p>`,
              null,
              null,
              null,
              'OK',
              'Cancel',
              'caseData',
              false
            )
            .afterClosed()
            .pipe(
              map((dialogueResponse) => {
                return {
                  dialogueResponse,
                  caseStateData: caseMetaData,
                };
              })
            );
        }),
        map((_) => {
          const isConfirmed = _.dialogueResponse;
          const caseMetaData = _.caseStateData;
          let isEmergencyCase = caseMetaData.caseType == CaseType.Emergency;
          if (isConfirmed) {
            if (isEmergencyCase) {
              this.store.dispatch(CaseActions.deActivateEmergencyCase());
            } else this.store.dispatch(CaseActions.activateEmergencyCase());
          }
        }),
        catchError((error: unknown) => {
          return of(CaseActions.activateEmergencyCaseFailure({ error }));
        })
      );
    },
    { dispatch: false }
  );

  activateEmergencyCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.activateEmergencyCase),
      concatLatestFrom(() => this.store.select(getCaseId)),
      switchMap(([_, caseId]) =>
        this.service.updateCaseType(caseId, CaseType.Emergency)
      ),
      concatLatestFrom(() => [
        this.store.select(selectCaseState),
        this.store.select(getAbcdProtocolForms_Sorted),
      ]),
      switchMap(([status, data, medicalProtocol]) => {
        const primaryProtocolData = data?.model?.triage;
        //If emergency case activated, then reset primary protocol answered that may
        //enable emergency case
        const updatedProtocolModel = this.getProtocolModel(
          medicalProtocol,
          primaryProtocolData,
          true
        );

        if (updatedProtocolModel?.protocol) {
          return [
            CaseActions.updateTriageProtocolModel({
              data: updatedProtocolModel,
            }),
            CaseActions.activateEmergencyCaseSuccess(),
            CaseActions.saveCase(),
          ];
        } else {
          return [
            CaseActions.activateEmergencyCaseSuccess(),
            CaseActions.saveCase(),
          ];
        }
      }),
      catchError((error: unknown) =>
        of(CaseActions.activateEmergencyCaseFailure({ error }))
      )
    );
  });

  deActivateEmergencyCase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.deActivateEmergencyCase),
      concatLatestFrom(() => this.store.select(getCaseId)),
      switchMap((_) => {
        let caseId = _[1];
        return this.service.updateCaseType(caseId, CaseType.Undefined);
      }),
      concatLatestFrom(() => [
        this.store.select(selectModel),
        this.store.select(getAbcdProtocolForms_Sorted),
      ]),
      switchMap(([isApiSuccess, caseData, medicalProtocol]) => {
        let primaryProtocolData = caseData?.triage;
        const triageEligibility = caseData?.eligibility?.triage;
        const caseType = Helpers.evaluateCaseType(triageEligibility);
        let updatedProtocolModel = this.getProtocolModel(
          medicalProtocol,
          primaryProtocolData,
          false
        );

        if (updatedProtocolModel?.protocol) {
          return [
            CaseActions.updateTriageProtocolModel({
              data: updatedProtocolModel,
            }),
            CaseActions.deActivateEmergencyCaseSuccess({ caseType }),
            CaseActions.saveCase(),
          ];
        } else {
          return [
            CaseActions.deActivateEmergencyCaseSuccess({ caseType }),
            CaseActions.saveCase(),
          ];
        }
      }),
      catchError((error: unknown) => {
        return of(CaseActions.deActivateEmergencyCaseFailure({ error }));
      })
    );
  });

  evaluateCaseType$ = createEffect(() => {
    return this.actions$.pipe(
      // when evaluate case is called
      ofType(CaseActions.evaluateCaseType),
      // then select case metaData from the store
      concatLatestFrom(() => this.store.select(selectCaseMetaData)),
      // and check if the case type is not the Emergency case
      filter(
        ([_, caseMetaData]) => caseMetaData?.caseType !== CaseType.Emergency
      ),
      // if not, calculate case type and update the store
      map(([action, currentState]) => {
        const caseData = action.data;
        const triageEligibility = caseData?.eligibility?.triage;
        const caseType = Helpers.evaluateCaseType(triageEligibility);
        return CaseActions.evaluateCaseTypeSuccess({ caseType });
      }),
      catchError((error: unknown) =>
        of(CaseActions.evaluateCaseTypeFailure({ error }))
      )
    );
  });

  // redirectToDashboard$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(CaseActions.deActivateEmergencyCaseSuccess),
  //     concatLatestFrom(() => this.store.select(getCaseId)),
  //     map((_) => {
  //       let caseId = _[1];

  //       const to = {
  //         path: [`nfr/case/${caseId}`],
  //       };
  //       return RouterActions.go({ to });
  //     }),
  //     catchError((error: unknown) => {
  //       return of(CaseActions.deActivateEmergencyCaseFailure({ error }));
  //     })
  //   );
  // });

  applyCaseRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.applyCaseRules),
      switchMap((caseRule, data) => {
        let fdata = {
          caseRule,
          data,
        };
        return of(fdata);
      }),
      concatLatestFrom(() => this.store.select(selectAssembly)),

      concatLatestFrom(() => [
        this.store.select(selectCaseMetaData),
        this.store.select(selectCaseState),
      ]),
      map((_) => {
        const rules = _[0][0].caseRule.rules;
        const assembly = _[0][1] as Assembly;
        const caseType = _[1];
        const caseStateData = _[2];
        const iforms = this.ruleService.getCaseNavigationByCaseBasedRule(
          assembly,
          rules,
          caseType,
          caseStateData
        );
        //1. apply navigation rules
        //2. We can call here action groups if we need to fire more actions
        return CaseActions.enableDisableMenu({ forms: iforms });
      })
    );
  });

  enableDisableMenu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.enableDisableMenu),
      concatLatestFrom(() => this.store.select(selectCurrentState)),
      map(([action, currentState]) => {
        return CaseActions.enableDisableMenuSuccess({
          forms: action.forms,
        });
      })
    );
  });

  updateStandardProtocolAnswer$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.updateStandardProtocolAnswer),
      concatLatestFrom(() => [
        this.store.select(selectModel),
        this.store.select(getAbcdProtocolForms_Sorted),
      ]),
      map((_) => {
        const parameterData = _[0]?.data;
        const isEmergencyCase = parameterData?.data?.isEmergencyCase ?? false;
        const caseData = _[1];
        const medicalProtocol = _[2];
        let primaryProtocolData = caseData?.triage;

        let updatedProtocolModel = this.getProtocolModel(
          medicalProtocol,
          primaryProtocolData,
          isEmergencyCase
        );
        return CaseActions.updateTriageProtocolModel({
          data: updatedProtocolModel,
        });
      }),
      catchError((error: unknown) => {
        return of(CaseActions.updateStandardProtocolAnswerError({ error }));
      })
    );
  });

  validateTelemedicine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.validateTelemedicine),
      delay(1000),
      concatLatestFrom(() => [
        this.store.select(selectAssembly),
        this.store.select(selectTelemedicineConfig),
        this.store.select(selectCaseMetaData),
      ]),
      map(([_data, _assembly, _teleMedConfig, _caseMetaData]) => {
        const selectedStateOfJurisdiction = _data.data.stateOfJurisdiction;
        const states = _assembly?.props?.datasets?.states as any[];
        const isTelemedicine =
          _caseMetaData?.isTelemedicineDispositionAvailable ?? false;

        const isActivateTelemedicineApplicable =
          this.isActivateTelemedicineApplicable(
            _teleMedConfig,
            selectedStateOfJurisdiction,
            states
          );

        if (isActivateTelemedicineApplicable && !isTelemedicine)
          return CaseActions.activateTelemedicine();
        else if (!isActivateTelemedicineApplicable && isTelemedicine)
          return CaseActions.deActivateTelemedicine();
        else return CaseActions.noopAction();
      }),
      catchError((error: unknown) => {
        return of(CaseActions.noopAction());
      })
    );
  });

  updateCaseModelCustom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.updateCaseModelCustom),
      map((_) => {
        return CaseActions.applyTelemedicineWorkflow();
      })
    );
  });
  applyTelemedicineWorkflow$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaseActions.applyTelemedicineWorkflow),
      debounceTime(500),
      concatLatestFrom(() => this.store.select(selectCurrentState)),
      map(([action, currentState]) => {
        const metaData = currentState?.case?.metaData;
        const model = currentState?.case?.model;
        let planOfCare = model?.planofcare;
        //Scenarios that result in a selected disposition = Self-Care. The user doesn't need to fill out the Provider tab.
        const selectedDisposition = Helpers.getSelectedDisposition(model);
        if (metaData?.selectedDisposition !== selectedDisposition) {
          let metaDataUpdated = {
            ...metaData,
            selectedDisposition: selectedDisposition,
          };
          if (selectedDisposition == SelectedDisposition.TeleMedicine) {
            planOfCare = {
              ...planOfCare,
              provider: {
                ...planOfCare?.provider,
                providerInformation: 'Concentra Telemed',
                providerName: 'Concentra Telemed',
                providerPhone: '855-835-6337',
                providerSelected: 'yes',
                providerType: '',
                providerRanking: '',
                hoursOperations: '',
                facilityName: '',
                providerAddress: '',
                agreementInformation: '',
                providerFax: '',
                otherReferrals: '',
                whyNot: '',
                otherReason: '',
                medicalProvider: '',
              },
            };
          } else if (selectedDisposition == SelectedDisposition.SelfCare) {
            planOfCare = {
              ...planOfCare,
              provider: {
                ...planOfCare?.provider,
                providerType: '',
                providerRanking: '',
                hoursOperations: '',
                facilityName: '',
                providerName: '',
                providerAddress: '',
                providerPhone: '',
                providerFax: '',
                otherReferrals: '',
                providerInformation: '',
                whyNot: '',
                otherReason: '',
                providerSelected: null,
                agreementInformation: '',
                medicalProvider: '',
              },
            };
          } else if (
            metaData?.selectedDisposition != undefined &&
            selectedDisposition == SelectedDisposition.Other
          ) {
            planOfCare = {
              ...planOfCare,
              provider: {
                ...planOfCare?.provider,
                providerType: '',
                providerRanking: '',
                hoursOperations: '',
                facilityName: '',
                providerName: '',
                providerAddress: '',
                providerPhone: '',
                providerFax: '',
                otherReferrals: '',
                providerInformation: '',
                providerSelected: null,
                agreementInformation: '',
                medicalProvider: '',
                otherReason: '',
                whyNot: '',
              },
            };
          }
          return CaseActions.applyTelemedicineWorkflowSuccess({
            provider: planOfCare?.provider,
            metaData: metaDataUpdated,
          });
        } else {
          return CaseActions.noopAction();
        }
      })
    );
  });

  customMessage(val: string) {
    const result = Object.values(CaseApiActionTypes).find(
      (value) => value === val
    );
    if (result === undefined) return '';
    switch (result) {
      case CaseApiActionTypes.CREATE_CASE_SUCCESS:
        return 'Case Creation Successful';
      case CaseApiActionTypes.CREATE_CASE_FAILURE:
        return 'Case Creation Failed';
      case CaseApiActionTypes.LOAD_CASE_SUCCESS:
        return 'Case Loading Successful';
      case CaseApiActionTypes.LOAD_CASE_FAILURE:
        return 'Error Loading Case';
      case CaseApiActionTypes.SAVE_CASE_SUCCESS:
        return 'Case saved';
      case CaseApiActionTypes.SAVE_CASE_FAILURE:
        return 'Case save failed';
      default:
        return '';
    }
  }

  applyCaseBasedRule(data) {
    const call = data.calls[0];
    const employee = call?.props?.employee;
    const triageData = data.data;

    if (triageData.eligibility == undefined && employee?.isAvailable) {
      _.set(triageData, 'eligibility.triage.injuryResultInDeathDisable', true);
    } else {
      _.set(triageData, 'eligibility.triage.injuryResultInDeathDisable', false);
    }

    if (
      triageData.eligibility?.triage?.injuryResultInDeath == undefined &&
      employee?.isAvailable
    ) {
      _.set(triageData, 'eligibility.triage.injuryResultInDeath', 'false');
      _.set(triageData, 'eligibility.triage.isEmployeeAvailable', 'true');
    }

    if (
      triageData.intake?.supervisor?.accidentDescription?.dateTimeOfInjury ===
        undefined &&
      call?.data?.eligibilityData?.dateOfInjury
    ) {
      _.set(
        triageData,
        'intake.supervisor.accidentDescription.dateTimeOfInjury',
        call?.data?.eligibilityData?.dateOfInjury
      );
      _.set(
        triageData,
        'triage.subjective.onset.whenDidSymptomsStartDate',
        call?.data?.eligibilityData?.dateOfInjury
      );

    }

    if (
      triageData.intake?.supervisor?.accidentDescription?.injuryTimeZone ===
        undefined &&
      call?.data?.eligibilityData?.timeZone
    ) {
      _.set(
        triageData,
        'intake.supervisor.accidentDescription.injuryTimeZone',
        call?.data?.eligibilityData?.timeZone
      );
    }

    if (
      triageData.intake?.supervisor?.accidentDescription
        ?.timeFromInjuryToReporting === undefined &&
      call?.data?.eligibilityData?.dateOfInjury &&
      call?.data?.eligibilityData?.timeZone &&
      call?.data?.startTime
    ) {
      const doi = call?.data?.eligibilityData?.dateOfInjury;
      const callStartTime = call?.data?.startTime;
      const timeZone = call?.data?.eligibilityData?.timeZone;
      const lagTime = this.getLagTimeHours(doi, callStartTime, timeZone);
      _.set(
        triageData,
        'intake.supervisor.accidentDescription.timeFromInjuryToReporting',
        lagTime
      );
    } else if (
      triageData.intake?.supervisor?.accidentDescription?.dateTimeOfInjury &&
      triageData.intake?.supervisor?.accidentDescription?.injuryTimeZone &&
      call?.data?.startTime
    ) {
      const doi =
        triageData.intake?.supervisor?.accidentDescription?.dateTimeOfInjury;
      const callStartTime = call?.data?.startTime;
      const timeZone =
        triageData.intake?.supervisor?.accidentDescription?.injuryTimeZone;
      const lagTime = this.getLagTimeHours(doi, callStartTime, timeZone);
      _.set(
        triageData,
        'intake.supervisor.accidentDescription.timeFromInjuryToReporting',
        lagTime
      );
    }

    if (triageData.intake?.supervisor?.accidentDescription?.details) {
      _.set(
        triageData,
        'triage.subjective.accident.injuryaccident',
        triageData.intake?.supervisor?.accidentDescription?.details
      );
    }

    if (
      triageData.intake?.supervisor?.accidentDescription?.dateReported ===
        undefined &&
      call?.data?.startTime
    ) {
      _.set(
        triageData,
        'intake.supervisor.accidentDescription.dateReported',
        call?.data?.startTime
      );
    }
    if (
      call?.data?.supervisor?.isCaller ||
      call?.data?.supervisor?.isAvailable == true
    ) {
      _.set(
        triageData,
        'supplemental.intake.employer.incidentReportedToSupervisor',
        'true'
      );
    } else {
      _.set(
        triageData,
        'supplemental.intake.employer.incidentReportedToSupervisor',
        'false'
      );
    }

    return triageData;
  }

  getLagTimeHours(doi, callStartTime, timeZone) {
    let doiTz = 'America/New_York';
    switch (timeZone) {
      case 'cst':
        doiTz = 'America/Chicago';
        break;
      case 'est':
        doiTz = 'America/New_York';
        break;
      case 'mst':
        doiTz = 'America/Denver';
        break;
      case 'pst':
        doiTz = 'America/Los_Angeles';
        break;
      case 'ast':
        doiTz = 'US/Alaska';
        break;
      case 'hst':
        doiTz = 'US/Hawaii';
        break;
    }
    // Parse the input dates and apply the separate timezone offset to 'doi'
    const doiDate = moment.tz(doi, doiTz);
    const utcDateParsed = moment.utc(callStartTime);
    const reportDateinTz = moment.tz(utcDateParsed, doiTz);
    // Calculate the difference in hours
    let differenceInHours = Math.floor(reportDateinTz.diff(doiDate, 'hours'));
    if (differenceInHours < 0) differenceInHours = 0;

    return differenceInHours;
  }

  bindEmployeeDetail(call, user) {
    const { employee, supervisor, location, another } = call.props || {};
    const { translation } = call.data || {};
    let supervisorPhone = '';

    let initialCaller = this.getInitialCaller(call);
    var creatededByModel = {
      firstName: user.firstName,
      lastName: user.lastName,
      credentials: user.credentials,
    };
    const metaData = {
      caseType: call.caseType,
      caseOrigin: call.caseOrigin,
      createdBy: creatededByModel,
      callerRole: initialCaller,
    };

    let locationData = {};
    if (location) {
      locationData = {
        work: {
          zip: location?.zip,
          city: location?.city,
          state: location?.state,
          code: location?.code,
          name: location?.name,
          //contact : location.contact,
          localPhone: location?.phoneNumber,
          address1: location?.address,
          address2: location?.address2,
          division: location?.division,
        },
      };
      supervisorPhone = location?.phoneNumber;
    }

    let obj: any;

    obj = {
      metaData,
      intake: {
        employee: {
          demographics: {
            firstName: employee?.firstName,
            lastName: employee?.lastName,
            email: employee?.email,
            jobTitle: employee?.jobTitle,
            middleNameInitial: employee?.middleNameInitial,
            employeeId: employee?.employeeId,
            ssnValue: employee?.ssnValue,
            ssn: employee?.ssn,
            gender: employee?.gender,
            maritalStatus: employee?.maritalStatus,
            dob: employee?.dob,
            phone: {
              home: employee?.home,
              work: employee?.work,
            },
          },
          address: {
            home: {
              address1: employee?.address1,
              address2: employee?.address2,
              state: employee?.city,
              city: employee?.state,
              zip: employee?.zip,
            },
          },
        },
        supervisor: {
          demographics: {
            firstName: supervisor?.firstName,
            lastName: supervisor?.lastName,
            contactPhone: supervisorPhone,
            email: supervisor?.email,
            jobTitle: supervisor?.jobTitle,
          },
        },
        another: {
          demographics: {
            firstName: another?.firstName,
            lastName: another?.lastName,
          },
        },
        location: locationData,
        accident:
          call.caseType == CaseType.Emergency
            ? {
                details: {
                  escalationCriteriaMet: 'Transported by Ambulance',
                },
              }
            : {},
      },
      planofcare:
        call.caseType == CaseType.Emergency
          ? {
              disposition: {
                dispositionRecommended: '911',
                directEmployee: 'Immediate',
              },
              provider: {
                medicalProvider: 'Ambulance',
              },
            }
          : {},
      translation: translation || {},
    };

    return obj;
  }

  completeCaseFailureNotify() {
    const dataPopUp = {
      message:
        'Oops! There was an error while saving your data when you clicked the Complete button. <BR/><BR/>Please Exit Claim and check again or contact support for assistance.',
      acceptButtonText: 'OK',
      cancelButtonText: '',
      title: 'Error',
      showCancelButton: false,
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '450px',
      height: '250px',
      backdropClass: 'dark-backdrop',
      disableClose: true,
      data: dataPopUp,
    });

    dialogRef
      .afterClosed()
      .pipe()
      .subscribe((result) => {
        if (!result) {
          setTimeout(() => {}, 100);
        }
      });
  }

  callBackEmergencyConfirm(arg) {
    // alert('called');
  }

  isActivateTelemedicineApplicable(
    teleMedConfig: TelemedicineConfigs,
    selectedStateOfJurisdiction,
    states: any[]
  ) {
    if (
      teleMedConfig?.isTelemedicineEnabled &&
      teleMedConfig?.applicableStateList?.length > 0 &&
      selectedStateOfJurisdiction &&
      states &&
      states.length > 0
    ) {
      const selectedStates = states.find(function (el) {
        return el.label == selectedStateOfJurisdiction;
      });
      let isExists = teleMedConfig.applicableStateList.some(
        (e) => e.value == selectedStates?.value
      );
      return isExists;
    } else return false;
  }
  public getProtocolModel(
    medicalProtocols: MedicalProtocol[],
    protocolData: any,
    isEmergencyCase: boolean = true
  ) {
    let clonedData = _.cloneDeep(protocolData);
    //if emergency de-activated, and any answer is yes, then clear this response
    //if emergency activated, and all answer are No, then clears the response
    if (isEmergencyCase) {
      const isAll911AnsweredNo = this.isAll911QuestionAnsweredNo(
        medicalProtocols,
        protocolData
      );
      if (isAll911AnsweredNo) {
        for (const protocol of medicalProtocols) {
          const filteredFields = protocol?.primaryForm?.fields?.filter(
            (el) =>
              (el.control === 'radioProtocol' || el.control === 'radio') &&
              (el.isTrigger911 === undefined || el.isTrigger911)
          );
          filteredFields.forEach((field) => _.set(clonedData, field.key, null));
        }
      }
    } else {
      for (const protocol of medicalProtocols) {
        const filteredFields = protocol?.primaryForm?.fields?.filter(
          (el) =>
            (el.control === 'radioProtocol' || el.control === 'radio') &&
            (el.isTrigger911 === undefined || el.isTrigger911)
        );
        filteredFields.forEach((field) => {
          const modelVal = _.get(protocolData, field.key);
          if (modelVal === 'Y') {
            _.set(clonedData, field.key, null);
          }
        });
      }
    }

    return clonedData;
  }
  private isAll911QuestionAnsweredNo(
    medicalProtocols: MedicalProtocol[],
    protocolData: any
  ) {
    for (const protocol of medicalProtocols) {
      const fields = protocol?.primaryForm?.fields;
      const filteredFields = fields.filter(
        (el) =>
          (el.control === 'radioProtocol' || el.control === 'radio') &&
          (el.isTrigger911 === undefined || el.isTrigger911 === true)
      );
      const isAllAnsweredNO = filteredFields.every(
        (e) => _.get(protocolData, e.key) === 'N'
      );
      if (!isAllAnsweredNO) {
        return false;
      }
    }
    return true;
  }
  getInitialCaller(call: any) {
    let initialCaller = '';
    if (call.props.another?.isCaller === 'true') {
      initialCaller = 'Another';
    } else {
      initialCaller =
        call.props.employee?.isCaller === 'true' ? 'Employee' : 'Supervisor';
    }
    return initialCaller;
  }
  private isAny911AnsweredYes(
    medicalProtocols: MedicalProtocol[],
    protocolData: any
  ) {
    for (const protocol of medicalProtocols) {
      const fields = protocol?.primaryForm?.fields;
      const filteredFields = fields
        .filter(
          (el) => el.control === 'radioProtocol' || el.control === 'radio'
        )
        .filter((el) => el.isTrigger911 === undefined || el.isTrigger911);
      if (filteredFields.some((e) => _.get(protocolData, e.key) === 'Y')) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private actions$: Actions,
    private store: Store,
    private service: CaseApiService,
    private forms: FormsApiService,
    private account: AccountFacade,
    private note: AlertService,
    private confirmDialog: ConfirmDialogService,
    private ruleService: RuleService,
    private dialog: MatDialog
  ) {}
}
