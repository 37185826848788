import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironmentConfig } from '@autobot/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OptionLookupService {
  
  getLookUpByName(lookupName: string): Observable<Options[]> {
    const src =
      this.environment.apiConfig.accountsUri + `/api/lookupdata/${lookupName}`;
    return this.http.get<Options[]>(src).pipe(retry(1), catchError(this.httpError.bind(this)));
  }

  httpError(error: any) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      msg = error.error.message;
    } else {
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    console.log(msg);
    return throwError(msg);
  }

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironmentConfig
  ) {}
}

export interface Options {
  label: string;
  value: string | number;
  children?: Options[];
}
