import { Component, inject } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { OptionLookupService, Options } from '../services/optionLookup.service';

@Component({
  selector: 'multi-level-dropdown',
  template: `
    <mat-form-field style="width:580px">
  <mat-label>{{props.label}}</mat-label>
  <mat-select
    [formControl]="formControl"
    #mySelect
    [(ngModel)]="selectedValue"
    [matMenuTriggerFor]="levelOne"
    #levelOneTrigger="matMenuTrigger"
    (focus)="levelOneTrigger.openMenu()"
    [matTooltip]="selectedValue"
    [matTooltipDisabled]="!selectedValue"
    [required]="props.required ?? false"
  >
    <mat-option *ngFor="let category of selectionList" [value]="category">{{
      category
    }}</mat-option>
  </mat-select>
</mat-form-field>
<mat-menu #levelOne="matMenu" direction="down" yPosition="below">
  <ng-container *ngFor="let menuItem of optionList">
    <span (mouseleave)="levelOneTrigger.closeMenu()"></span>
    <ng-container>
      <li mat-menu-item [matMenuTriggerFor]="levelTwo" 
      [ngClass]="{'selectedMenu': selectedValue.includes(menuItem.label)}">
        <span>{{ menuItem.label }}</span>
      </li>
      <mat-menu #levelTwo="matMenu">
        <ng-container *ngFor="let child2 of menuItem.children">
          <button
            mat-menu-item
            (click)="onDropdownItemSelected(menuItem, child2)"
            [ngClass]="{'selectedMenu': selectedValue === (menuItem.label + ' - ' + child2.label)}"
          >
            {{ child2.label }}
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
  </ng-container>
</mat-menu>

  `,
  styles: [
    `
    .selectedMenu {
      color: black;
      background-color: lightgray;
  }
 `,
  ],
})
export class multiLevelDropdownType extends FieldType<FieldTypeConfig> {

  public classifications: string[] = [];
  public selectedValue = '';
  public selectionList = [];
  public optionList: Options[] = [];

  svc = inject(OptionLookupService);
  ngOnInit() {
    this.svc.getLookUpByName(this.props.dataHint).subscribe((data: Options[]) => {
      if (!this.formControl.disabled) {
        this.optionList = data;
      }
    });

    if (this.formControl.value) {
      this.selectionList = [];
      this.selectionList.push(this.formControl.value);
      this.selectedValue = this.formControl.value;
    }

  }

  onDropdownItemSelected(menuItem: any, child: any) {
    if (!this.formControl.disabled) {
      let val = `${menuItem.label} - ${child.label}`;
      this.selectionList = [];
      this.selectionList.push(val);
      this.selectedValue = val;
    }
  }

}

export const multiLevelDropdown = {
  name: 'multi-level-dropdown',
  component: multiLevelDropdownType,
};


